import React from 'react';
import { loadFeedSource, loadUserProfile, updateUserProfile } from '../actions/settings';
import FeedSourceLine from '../components/FeedSourceLine';


class SelectAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      returnMsg: '',
      showForm: true,
      id: this.props.userId, // this.props.match.params.id,
      aoidescr: '',
      state: 'start',
      results: null,
      userProfile: null,
      ms: [1],
      es: null,
    };
  }

  closeModal = () => {
    this.props.closeSelectedAlerts();
  }

  onSubmit = e => {
    e.preventDefault();
    const layers = null;
    updateUserProfile(this.props.token, this.props.user.id, this.state.es, this.state.ms, layers, null)
      .then(res => {
        if (res == 200) {
          this.setState({ state: 'success' }, () => {
            loadUserProfile(this.props.token, this.props.user.id)
              .then(res2 => {
                let userProfile = res2.data[0].fields;
                this.props.setUserProfile(userProfile);
                if (this.props.firstLoginStep === "alerts")
                  this.props.closeSelectedAlerts("Your initial settings have been saved!")
                else
                  this.props.closeSelectedAlerts("Your selected alerts have been saved!")
              })
          });
        }
      });
  }

  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.loadInfo(this.props.token, this.props.user.id);
    }
    else {
      loadFeedSource()
        .then(res => {
          this.setState({ results: res.data })
        })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isAuthenticated !== prevProps.isAuthenticated) {
      if (this.props.isAuthenticated) {
        this.loadInfo(this.props.token, this.props.user.id);
      }
      else {
        loadFeedSource()
          .then(res => {
            this.setState({ results: res.data })
          })
      }
    }
  }

  loadInfo(token, user_id) {
    loadFeedSource()
      .then(res => {
        this.setState({ results: res.data });
        loadUserProfile(token, user_id)
          .then(res2 => {
            let userProfile = res2.data[0].fields;
            this.props.setUserProfile(userProfile);
            this.setState({ userProfile: userProfile });
            this.createMapSelection();
            this.createEmailSelection();
            this.setState({ state: 'ready' })
          })
      })
  }

  createMapSelection() {
    let ms = []
    if (this.state.userProfile.map_selection !== null) {
      ms = this.state.userProfile.map_selection.replace('[', '').replace(']', '');
      ms = ms.replace(/"/g, '').replace(/ /g, '');
      ms = ms.split(',');
      if (ms[0] === "") ms = [];
      else
        for (var i = 0; i < ms.length; i++) { ms[i] = parseInt(ms[i]); }
    }
    this.setState({ ms });
  }

  createEmailSelection() {
    let es = [];
    if (this.state.userProfile.email_selection !== null) {
      es = this.state.userProfile.email_selection.replace('[', '').replace(']', '');
      es = es.replace(/"/g, '').replace(/ /g, '');
      es = es.split(',');
      if (es[0] === "") es = [];
      else
        for (var i = 0; i < es.length; i++) { es[i] = parseInt(es[i]); }
    }
    this.setState({ es });
  }

  clickEmailSelected = (clicked, source_id) => {
    let es = this.state.es;
    if (clicked)
      if (!es.includes(source_id))
        es.push(source_id);
    if (!clicked)
      if (es.includes(source_id))
        es = this.arrayRemove(es, source_id);
  }

  clickMapSelected = (clicked, source_id) => {
    let ms = this.state.ms;
    if (clicked)
      if (!ms.includes(source_id))
        ms.push(source_id);
    if (!clicked)
      if (ms.includes(source_id))
        ms = this.arrayRemove(ms, source_id);
    this.setState({ ms }, () => { if (this.props.userProfile === null) this.props.setSelectedFeeds(this.state.ms) });
  }

  arrayRemove = (arr, value) => {
    return arr.filter(function (ele) {
      return ele != value;
    });

  }

  selectAllNoneEmail = () => {
    const newSelect = !this.state.es.includes(this.state.results[0].pk);
    let results = this.state.results;
    let es = this.state.es;
    results.forEach((result, resultX) => {
      const pk = result.pk;
      const pkX = es.indexOf(pk);
      if (!newSelect && pkX >= 0) es.splice(pkX, 1);
      if (newSelect && pkX === -1) es.push(pk);
    })
    this.setState({ es })
  }

  selectAllNoneMap = () => {
    const newSelect = !this.state.ms.includes(this.state.results[0].pk);
    let results = this.state.results;
    let ms = this.state.ms;
    results.forEach((result, resultX) => {
      const pk = result.pk;
      const pkX = ms.indexOf(pk);
      if (!newSelect && pkX >= 0) ms.splice(pkX, 1);
      if (newSelect && pkX === -1) ms.push(pk);
    })
    this.setState({ ms }, () => { if (this.props.userProfile === null) this.props.setSelectedFeeds(this.state.ms) });
  }

  render() {
    return (
      <div id="bboxUpdate" style={{ "marginTop": "0.75rem" }}>
        <div>
          <form onSubmit={this.onSubmit} className={this.state.showForm !== true ? 'hidden' : ''}>
            <div>
              {((this.props.userProfile !== null && this.state.state === 'ready') || this.props.userProfile === null) &&
                <div>
                  {this.props.userProfile !== null && this.props.userProfile.map_selection === null &&
                    <div>This is the first time you've selected alerts to show on the map.
                    </div>
                  }
                  <div id="selectAlertsTableContainer">
                    <table className="selectAlertsTable">
                      <thead>
                        <tr>
                          {this.props.userProfile !== null &&
                            <th style={{ "textAlign": "center" }}>Email<br /><br /><a onClick={this.selectAllNoneEmail}>All/None</a>
                            </th>
                          }
                          <th style={{ "width": "6.25rem", "textAlign": "center" }}>
                            Show
                            {this.props.userProfile !== null &&
                              <span>
                                <br /><br />
                                <a onClick={this.selectAllNoneMap} >All/None</a>
                              </span>
                            }
                          </th>
                          <th style={{ "verticalAlign": "bottom" }}>Alert</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.results && this.state.results.map((fs, index) => {
                          /* 
                            The feedsorce either doesn't belong to an organization, or it belongs to the same organization
                            as what's in userProfile.
                          */
                          if (fs.fields.org === null ||
                            (fs.fields.org !== null && this.props.userProfile !== null && this.props.userProfile.org !== null && this.props.userProfile.org === fs.fields.org))
                            return (
                              <FeedSourceLine
                                key={index}
                                pk={fs.pk}
                                email={this.props.user.email}
                                org={fs.fields.org}
                                public={fs.fields.public}
                                name={fs.fields.name}
                                descr={fs.fields.descr}
                                source_group={fs.fields.source_group}
                                email_selected={this.state.userProfile && this.state.es.includes(fs.pk) ? true : false}
                                map_selected={this.state.ms.includes(fs.pk) ? true : false}
                                clickEmailSelected={this.clickEmailSelected}
                                clickMapSelected={this.clickMapSelected}
                                map_marker={fs.fields.map_marker}
                                userProfile={this.props.userProfile}
                              />
                            )
                        })}
                      </tbody></table>
                  </div>
                  {this.props.userProfile !== null &&
                    <button type="submit"
                      className="mapOptionButton"
                      style={{ "marginTop": "0.875rem", "fontSize": "1rem" }}
                    >Update My Selected Alerts</button>
                  }
                  &nbsp;
                  {this.props.userProfile !== null &&
                    <button type="reset"
                      className="mapOptionButton"
                      style={{ "marginTop": "0.875rem", "fontSize": "1rem" }}
                      onClick={this.closeModal}>
                      Cancel
                    </button>
                  }
                </div>
              }
            </div>
          </form>
        </div>
      </div>
    )
  }
}


export default SelectAlerts;
